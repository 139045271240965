import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from 'react-router-dom';
// import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const styles = theme => ({
  link: {
    
    color: 'inherit'
  },
  text: {
    display: 'inline-block',
    verticalAlign: 'text-bottom'
  }
});

class BottomNavi extends Component {
  render() {
    const { classes, back, backText='Back to Home', backPath="/", backState={}, next, nextText='Next', nextPath='/', nextState={} } = this.props;
    let navi = {};
    if (back) {
      navi = (<div>
        <Link variant="body2" to={{ pathname: backPath, state: backState }}>
          <span className={classes.text}>{backText}</span>
        </Link>
      </div>);
    }
    if (next) {
      navi = (<>
      <div>
        <Link variant="body2" to={{ pathname: nextPath, state: nextState }}>
          <span className={classes.text}>{nextText}</span>
        </Link>
      </div>        
      </>)
    }
    return navi;
  }
}

export default withRouter(withStyles(styles)(BottomNavi));