import React, { Component } from "react";
import NumberFormat from 'react-number-format';
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import stateData from "../data/state";
import Topbar from "./Topbar";
import { getYearsCombo, getStatesCombo } from "./common/Utils";
import Box from "@material-ui/core/Box";


const backgroundShape = require("../images/shape.svg");

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["100"],
    overflow: "hidden",
    background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    paddingBottom: 200
  },
  grid: {
    width: 1200,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1),
    width: 152
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  block: {
    padding: theme.spacing(2)
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  noBorder: {
    borderBottomStyle: "hidden"
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: "absolute",
    top: "40%",
    left: "40%"
  },
  formSpacing: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    width: '55ch'
  },
  noPadding: {
    padding: "0px"
  }
});

const taxyears = getYearsCombo();
const stateValues = getStatesCombo();

class Main extends Component {
  

  constructor(props) {
    super(props)

    this.state = {
      taxYear: null,
      taxableIncomeError: false,
      taxYearError: false,
      filingStatusError: false,
      filingStatus: null,
      taxableIncome: null,
      stateCode: null,
      stateError: false
    };
}
  componentDidMount() {
    // let brackets = getBrackets(2012, Constants.MARRIED_FILING_JOINT);
    // let computedBrackets = computeYourTax(brackets, 71200);
    // console.log("getBrackets", brackets);
    // console.log("computedBrackets", computedBrackets);

    // const filingStatus =null;
    // const taxYear = null;
    // const taxableIncome = null; 
    
    if (this.props.location && this.props.location.state) {
      const {filingStatus, taxYear, taxableIncome} = this.props.location.state;
      // console.log("main location state", filingStatus, taxYear, taxableIncome);
      this.setState({filingStatus, taxYear, taxableIncome});
      }
  }

  handleStateChange = event => {
    // console.log('handleTaxyearChange', event.target.value);
    this.setState({stateCode: event.target.value});
  }

  handleTaxyearChange = event => {
    // console.log('handleTaxyearChange', event.target.value);
    this.setState({taxYear: event.target.value});
  }

  handleFilingStatusChange = event => {
    // console.log("handleFilingStatusChange", event.target.value);
    this.setState({filingStatus: event.target.value});
  }

  handleTaxableIncomeChange = event => {
    this.setState({taxableIncome: event.target.value});
  }

  handleNext = event => {
    let error = false;
    let name = document.getElementById('form-your-name').value;
    // let taxYear = document.getElementById('form-tax-year').value;
    // let filingStatus = document.getElementById('form-filing-status').value;
    let taxableIncome = document.getElementById('form-taxable-income').value;
    // console.log("handleNext", taxableIncome);

    if (!this.state.taxYear) error = true;
    if (!this.state.filingStatus) error = true;
    if (!this.state.stateCode) error = true;
    if (!taxableIncome) error = true;
    this.setState({taxYearError: !this.state.taxYear ? true : false})
    this.setState({filingStatusError: !this.state.filingStatus ? true : false})
    this.setState({stateError: !this.state.stateCode ? true : false})
    this.setState({taxableIncomeError: !taxableIncome ? true : false})

    if (!error) 
    {  
      if (this.props.history) {
        this.props.history.push(
          '/taxresults',
          {
            'name': name,
            'taxYear': this.state.taxYear,
            'filingStatus': this.state.filingStatus,
            'taxableIncome': taxableIncome,
            'stateCode': this.state.stateCode
        });
      }      
    }
  }

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    )
  }

  render() {
    const { classes } = this.props;
    // console.log("Render props", this.props.history);
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar />
        <div className={classes.root}>
          <Grid container justify="center">
            <Grid
              spacing={4}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}
            >
              <Grid container item xs={12}>
                <Grid item>
                  <Paper className={classes.paper}>
                    <div>
                      <Box pb={5}>
                      <div>
                        <Typography variant="h3" >
                          View your taxes
                        </Typography>
                        <Typography variant="body1" >
                          How much will you pay in taxes this year?  How much did you pay last year? 
                          What's your marginal tax rate?  What's your effective tax rate? <br />
                          Other websites will give you only the tax tables for the current and maybe one extra year. 
                          Our website will allow you to not only view the Federal tax table for the current year, but view 
                          it for other years as well and compare what you would've paid. 
                        </Typography>
                        <Typography variant="body1" >
                          <br />
                          To get started, fill in the questions below and click on Next to find out how much taxes you owe.  You can
                          then compare tax tables and view timeline of your taxes based on your filing status and taxable income. 
                        </Typography>
                      </div>
                      </Box>
                      <form noValidate autoComplete="off">
                        <div>
                          <TextField className={classes.formSpacing} id="form-your-name" label="Your Name" aria-label="Your name" />
                        </div>
                        <div>
                        <TextField
                          error={this.state.taxYearError}
                          className={classes.formSpacing}
                          id="form-tax-year"
                          select
                          label="Tax Year"
                          aria-label="Tax Year"
                          value={this.state.taxYear}
                          onChange={this.handleTaxyearChange}
                        >
                          {taxyears.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        </div>
                        <div>
                          <TextField value={this.state.taxableIncome} 
                            error={this.state.taxableIncomeError} 
                            className={classes.formSpacing} 
                            id="form-taxable-income" 
                            label="Your Taxable Income"
                            aria-label="Your taxable Income"
                            onChange={this.handleTaxableIncomeChange}
                            InputProps={{
                              inputComponent: this.NumberFormatCustom,
                            }}
                          />
                        </div>
                        <div>
                          <TextField
                            error={this.state.stateError}
                            className={classes.formSpacing}
                            id="form-state-code"
                            select
                            label="State"
                            aria-label="State"
                            value={this.state.stateCode}
                            onChange={this.handleStateChange}
                          >
                            {stateValues.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div>
                        <FormControl id="form-filing-status" component="fieldset" className={classes.formSpacing} error={this.state.filingStatusError}>
                          <FormLabel component="legend">Filing Status</FormLabel>
                          <RadioGroup aria-label="Filing Status" name="filingStatus" value={this.state.filingStatus} onChange={this.handleFilingStatusChange}>
                            <FormControlLabel value="MFJ" control={<Radio />} label="Married Filing Joint" />
                            <FormControlLabel value="MFS" control={<Radio />} label="Married Filing Separate" />
                            <FormControlLabel value="HOH" control={<Radio />} label="Head of Household" />
                            <FormControlLabel value="SIN" control={<Radio />} label="Single" />
                          </RadioGroup>
                        </FormControl>
                        </div>
                      </form>
                      <div className={classes.alignRight}>
                        <Button
                          aria-label="Click Here"
                          color="primary"
                          variant="contained"
                          className={classes.actionButtom}
                          onClick={this.handleNext}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Main));
