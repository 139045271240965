export default class Constants {
  static MARRIED_FILING_JOINT = "MFJ";
  static MARRIED_FILING_SEPARATE = "MFS";
  static SINGLE = "SIN";
  static HEAD_OF_HOUSEHOLD = "HOH";

  static getDesc(code) {
    switch(code) {
      case this.MARRIED_FILING_JOINT : return "Married Filing Joint"
      case this.MARRIED_FILING_SEPARATE : return "Married Filing Separate"
      case this.SINGLE : return "Single"
      case this.HEAD_OF_HOUSEHOLD : return "Head of Household"
      default:  return "";
    }
  };
}