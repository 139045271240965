import Typography from '@material-ui/core/Typography';
import React from 'react';


export default function Footer() {
  return (
    <Typography variant="caption" display="block" gutterBottom>
      This website only provides estimates for the U.S. federal taxes based on published tax tables.  You may use it for analytical or comparison purposes.
    </Typography>
  );
}