import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Constants from './Constants';

const options = [
  {
    name: Constants.MARRIED_FILING_JOINT,
    value: Constants.getDesc(Constants.MARRIED_FILING_JOINT),
  },
  {
    name: Constants.MARRIED_FILING_SEPARATE,
    value: Constants.getDesc(Constants.MARRIED_FILING_SEPARATE)
  },
  {
    name: Constants.SINGLE,
    value: Constants.getDesc(Constants.SINGLE)
  },
  {
    name:Constants.HEAD_OF_HOUSEHOLD,
    value: Constants.getDesc(Constants.HEAD_OF_HOUSEHOLD)
  }
];

export default function FilingStatusPopper(props) {
  const { currentFilingStatus, filingStatusCallback } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  useEffect(()=> {
    // console.log("useEffect currentYear", options, currentYear);
    setSelectedIndex(options.findIndex(elem => elem.name === currentFilingStatus));
  }, [currentFilingStatus]);

  useEffect(() => {
    filingStatusCallback(options[selectedIndex].name);
  }, [selectedIndex])


  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    // <Grid container direction="column" alignItems="center">
      // <Grid item xs={12}>
      <>
        <ButtonGroup  ref={anchorRef} aria-label="split button">
          <Button onClick={handleClick}>{options[selectedIndex].value}</Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.name}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
      // </Grid>
    // </Grid>
  );
}
