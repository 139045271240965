import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles'
import './App.css';
import Routes from './routes'
import { blue, indigo } from '@material-ui/core/colors'
import Grid  from '@material-ui/core/Grid';
import Footer from './components/common/Footer';
import GoogleWrapper from './components/GoogleWrapper';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: blue[900]
    },
    primary: {
      main: indigo[700]
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});


class App extends Component {
  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Router basename="/">
            <GoogleWrapper>
            <Grid container
                  direction="column"
                  justify="space-between"
                  alignItems="stretch"
            >
              <Routes />
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Footer />
              </Grid>
            </Grid>
            </GoogleWrapper>
          </Router>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
