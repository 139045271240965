import React, { Component } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from 'react-router-dom';
// import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  text: {
    display: 'inline-block',
    verticalAlign: 'text-bottom'
  }
});

class TopArrows extends Component {
  render() {
    const { classes, back, backText='Back to Home', backPath="/", backState={}, next, nextText='Next', nextPath='/', nextState={} } = this.props;
    let navi = {};
    if (back) {
      navi = (<div>
        <Typography variant="h6" gutterBottom>
          <Link className={classes.link} to={{ pathname: backPath, state: backState }}>
            <KeyboardArrowLeft />
            <span className={classes.text}>{backText}</span>
          </Link>
        </Typography>
      </div>);
    }
    if (next) {
      navi = (<>
      <div>
        <Grid container direction="row" justify="space-between">
          <Typography variant="h6" gutterBottom>
            <Link className={classes.link} to={{ pathname: backPath, state: backState }}>
              <KeyboardArrowLeft />
              <span className={classes.text}>{backText}</span>
            </Link>
          </Typography>
          <Typography variant="h6" gutterBottom>
            <Link className={classes.link} to={{ pathname: nextPath, state: nextState }}>
              <span className={classes.text}>{nextText}</span>
              <KeyboardArrowRight />
            </Link>
          </Typography>
        </Grid>
      </div>        
      </>)
    }
    return navi;
  }
}

export default withRouter(withStyles(styles)(TopArrows));