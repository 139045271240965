import React, { Component } from 'react'
import { BrowserRouter as Router, Route, HashRouter, Switch, withRouter } from 'react-router-dom'
import Main from './components/Main'
import ScrollToTop from './components/ScrollTop'
import SingleYearTable from './components/SingleYearTable';
import TaxRangeResults from './components/TaxRangeResults'
import AboutUs from './components/AboutUs'
import FAQs from './components/FAQs';

class Routes extends Component {

  render()
  {
    return (
    // <Router basename="/">
     <ScrollToTop>
        <Switch>
          <Route exact path='/' component={ Main } />
          <Route exact path='/taxresults' component={ SingleYearTable } />
          <Route exact path='/timeline' component={ TaxRangeResults } />
          <Route exact path='/aboutus' component={ AboutUs } />
          <Route exact path='/faqs' component={ FAQs } />
        </Switch>
     </ScrollToTop>
    // </Router>
    )
  }
}
export default withRouter(Routes);