import React from 'react';
import { withRouter } from "react-router-dom";

const GoogleWrapper = (props) => {
  if (window.currentLocation === undefined || window.currentLocation !== props.history.location.pathname) {
    window.currentLocation = props.history.location.pathname;
    // console.log("pathname, ", props.history.location.pathname);
    window.gtag('config', 'G-0QT9EF9WM7', {'page_path': props.history.location.pathname});

  }
  return (
    props.children
  )
};

export default withRouter(GoogleWrapper);