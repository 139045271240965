import states2023 from './states-2023.json';
import states2022 from './states-2022.json';
import states2021 from './states-2021.json';
import states2020 from './states-2020.json';
import states2019 from './states-2019.json';
import states2018 from './states-2018.json';
import states2017 from './states-2017.json';
import states2016 from './states-2016.json';
import states2015 from './states-2015.json';

// console.log('states2022', states2022);
// console.log('states2021', states2021);
// console.log('states2020', states2020);
// console.log('states2019', states2019);
// console.log('states2018', states2018);
// console.log('states2017', states2017);
// console.log('states2016', states2016);
// console.log('states2015', states2015);
// console.log('states2022', states2022);

const stateData = [
  states2023,
  states2022,
  states2021,
  states2020,
  states2019,
  states2018,
  states2017,
  states2016,
  states2015
]

export default [
  states2023,
  states2022,
  states2021,
  states2020,
  states2019,
  states2018,
  states2017,
  states2016,
  states2015
];