import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Link as LinkMUI } from "@material-ui/core";
import Topbar from "./Topbar";
import { getYearsCombo } from "./common/Utils";
import Box from "@material-ui/core/Box";

const backgroundShape = require("../images/shape.svg");

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["100"],
    overflow: "hidden",
    background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    paddingBottom: 200
  },
  grid: {
    width: 1200,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1),
    width: 152
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  block: {
    padding: theme.spacing(2)
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  noBorder: {
    borderBottomStyle: "hidden"
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: "absolute",
    top: "40%",
    left: "40%"
  },
  formSpacing: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    width: '55ch'
  }
});

const taxyears = getYearsCombo();

class FAQs extends Component {
  

  constructor(props) {
    super(props)

    this.state = {
    };
}
  componentDidMount() {
  }



  render() {
    const { classes } = this.props;
    // console.log("Render props", this.props.history);
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar />
        <div className={classes.root}>
          <Grid container justify="center">
            <Grid
              spacing={4}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}
            >
              <Grid container item xs={12}>
                <Grid item>
                  <Paper className={classes.paper}>
                    <div>
                      <Box pb={5}>
                      <div>
                        <Typography variant="h3" >
                          FAQs
                        </Typography>
                        <br />
                        <Typography variant="h4" >
                          What is my tax bracket?
                        </Typography>
                        <Typography variant="body1" >
                          Often times, when people in the media talk about the tax bracket, they over simplify and refer to the top braket you fall into.
                          For example, suppose your filing status is Single and your taxable income is $40,500.   For the 2020 tax year, your tax bracket is 
                          22%.  Does that mean the IRS is taking 22% of your income?   The answer is "No".  First off, we're talking about "Taxable Income".  Which means
                          your income, that is your gross income reported on your W-2, will be more more than $40,500.   But after you subtract your exemptions, standard deduction, and other credits,
                          you're left with $40,500 which the government will tax.   But even then, the IRS is not taking 22% of $40,500.  The IRS calculates your taxes in buckets.  
                          Essentially 22% is the topmost bucket and the IRS actually will take only about $80 at that 22% rate.
                          You can play around with the numbers by clicking <Link variant="body2" to={{pathname: '/taxresults'}}>here</Link>.  
                        </Typography>
                        <br />
                        <Typography variant="h4" >
                          What is my effective tax rate?
                        </Typography>
                        <Typography variant="body1" >
                          Your effective tax rate is not the same thing as your tax bracket (as indicated above).  After you calculate the tax you owe 
                          at each tax bucket, you simply add up the total of all the buckets and divide by your taxable income.  For example, for tax year
                          2020, if you are single and your taxable income is $40,500, then you would owe approximately $4,700 in taxes.  If you divide 
                          that by your taxable income, you get an effective tax rate of 11.6%. 
                        </Typography>
                        <br />
                        <Typography variant="h4" >
                          Did I really get a tax break?
                        </Typography>
                        <Typography variant="body1" >
                          If you're like most people, you probably forgot what you actually paid in taxes 4 years ago (or maybe even last year!).  How do you know
                          if you really would've gotten a tax break under Obama or Trump?  Or will you get a tax break under Biden?  Lucky for you, you can view the 
                          published tax tables for multiple years by clicking <Link variant="body2" to={{pathname: '/taxresults'}}>here</Link>.  You can then click 
                          on the "Timeline View" on the top right to get a bar graph of how much taxes you would've paid under different tax years.  It will give you a clear
                          indication of whether you got a tax break or not.  
                        </Typography>
                        <br />
                        <Typography variant="h4" >
                          What is SALT?
                        </Typography>
                        <Typography variant="body1" >
                          Put simply, SALT stands for State And Local Taxes.  As a resident of any particular state, you typically have to pay some taxes that the
                          state imposes, such as property taxes for owning real estate.  Or personal property taxes for owning boats or other recreational 
                          vehicles.  There are a lot of rules around what you can or cannot deduct.  You can read more by clicking <LinkMUI href="https://www.policygenius.com/taxes/the-state-and-local-tax-deduction/" target="_blank" rel="noopener" >here</LinkMUI>.
                          It is the total amount of taxes you can deduct from your federal income tax return.  
                          One important thing to note about SALT is that the Trump administration passed a law, known as the Tax Cuts and Jobs Act of 2017 
                          (TCJA), which put a cap on the amount of SALT that can be deducted.  It is set to expire in 2025 (unless Congress makes it permanent)
                          but it will affect anyone in a high cost of living area that may depend on the higher deduction amount.  
                        </Typography>
                      </div>
                      </Box>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(FAQs));
