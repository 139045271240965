import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(0, 0, 0),
  },
}));


export default function UsefulLinks() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* <Grid container spacing={2}> */}
        {/* <Grid item xs={12} md={6}> */}
          <Typography variant="h6" className={classes.title}>
            Useful Links
          </Typography>
          <div className={classes.demo}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<a target='_blank' href='https://taxfoundation.org/tax-basics/salt-deduction/'>SALT deduction</a>}
                />
              </ListItem>,
            </List>
          </div>
        {/* </Grid> */}
      {/* </Grid> */}
    </div>
  );
}
