import React from "react";
import TableBody from '@material-ui/core/TableBody';
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { formatCurrency, formatPercent } from '../common/Utils';
import BottomNavi from "../common/BottomNavi";

const SingleYearTabContent = ({computedBrackets, totalTaxes, newTaxableIncome, naviState, dataAvailable}) => {
  const noData = <>
  <TableBody>
      <TableRow>
        <TableCell><Typography variant="body1" span={4}>{dataAvailable}</Typography></TableCell>
      </TableRow>
  </TableBody>
  </>;
  return   !dataAvailable ?
  (
    <TableBody>
      <TableRow>
        <TableCell><Typography variant="body1">Bracket</Typography></TableCell>
        <TableCell align="right"><Typography variant="body1">Range</Typography></TableCell>
        <TableCell align="right"><Typography variant="body1">Your Tax</Typography></TableCell>
        <TableCell align="right"><Typography variant="body1">Sum</Typography></TableCell>
      </TableRow>
    {computedBrackets.map((row, i) => (
      <TableRow key={i}>
        <TableCell><Typography variant="body1">{row.rate}%</Typography></TableCell>
        <TableCell align="right"><Typography variant="body1">{formatCurrency(row.incomeLow)} - {row.incomeHigh ? formatCurrency(row.incomeHigh) : "$"}</Typography></TableCell>
        <TableCell align="right"><Typography variant="body1">{formatCurrency(row.incomeTax)}</Typography></TableCell>
        <TableCell align="right"><Typography variant="body1">{formatCurrency(row.accumulated)}</Typography></TableCell>
      </TableRow>
    ))}

    <TableRow>
      <TableCell rowSpan={1} />
      <TableCell rowSpan={1} />
      <TableCell colSpan={1} align="right"><Typography variant="body1">Total Taxes Paid</Typography></TableCell>
      <TableCell align="right"><Typography variant="body1">{formatCurrency(totalTaxes.incomeTax)}</Typography></TableCell>
    </TableRow>
    <TableRow>
      <TableCell rowSpan={1} />
      <TableCell rowSpan={1} />
      <TableCell colSpan={1} align="right"><Typography variant="body1">Effective Tax Rate</Typography></TableCell>
      <TableCell align="right"><Typography variant="body1">{formatPercent((totalTaxes.incomeTax/newTaxableIncome))}</Typography></TableCell>
    </TableRow>
    <TableRow>
      <TableCell align="left"><Typography variant="body1"><BottomNavi back={true} backPath="/" backText='Back' backState={naviState} /></Typography></TableCell>
      <TableCell rowSpan={1} />
      <TableCell rowSpan={1} />
      <TableCell align="right"><Typography variant="body1"><BottomNavi next={true} nextPath="/timeline" nextState={naviState} /></Typography></TableCell> 
    </TableRow>
  </TableBody>
  )
  :
  (
    noData
  )
};

export default SingleYearTabContent;