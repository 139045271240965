import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { formatCurrency } from './Utils';


export default function IncomePopper(props) {
  const { currentIncome, incomeCallback } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [updatedIncome, setUpdatedIncome] = React.useState();
  const [userIncome, setUserIncome] = React.useState();

  useEffect(()=> {
    // console.log("useEffect currentYear", options, currentYear);
    setUpdatedIncome(currentIncome);
  }, [currentIncome]);

  useEffect(() => {
    if (currentIncome != updatedIncome)
      incomeCallback(updatedIncome);
  }, [updatedIncome])


  const handleClick = (e) => {
    // let newVal = document.getElementById("form-updated-income").value;
    setUpdatedIncome(formatCurrency(userIncome));
    setOpen(false);
  };

  const handleInputChanged = (e) => {
    // console.log("handleInputChanged", e);
    // if (e.target) {
      // e.target.focus();
      setUserIncome(e.target.value);
    // }
  }


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
              ref: inputRef,
              values: values
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    )
  }


  return (
    // <Grid container direction="column" alignItems="center">
      // <Grid item xs={12}>
      <>
        <ButtonGroup  ref={anchorRef} aria-label="split button">
          <Button onClick={handleClick}>{updatedIncome}</Button>
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <TextField  variant="outlined" id="form-updated-income"
                    autoFocus
                    value={userIncome}
                    onChange={handleInputChanged}
                    InputProps={{ endAdornment:
                      <InputAdornment position="end">
                        <Button
                          onClick={handleClick}
                          aria-label="Update Income"
                        >
                          Ok
                        </Button>
                      </InputAdornment>
                    , inputComponent: NumberFormatCustom}}
                    id="form-taxable-income" 
                  />
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
      // </Grid>
    // </Grid>
  );
}
