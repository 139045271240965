import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import withTheme  from '@material-ui/styles/withTheme';
import { getBarFill } from './common/Utils';


class SimpleBarChart extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/simple-bar-chart-tpz8r';
  render() {
    const { theme, data } = this.props;
    // console.log("SimpleBarChart", theme.palette);
    return (
      <React.Fragment>
      <ResponsiveContainer width="99%" height={500}>
        <BarChart
          // width={500}
          // height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="TaxYear" />
          <YAxis dataKey="IncomeTax" />
          <Tooltip />
          <Legend />
          <Bar dataKey="IncomeTax" fill={theme.palette.primary.main}>
            {data && data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={ getBarFill(entry.TaxYear, theme) } />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      </React.Fragment>
    );
  }
}

export default withTheme(SimpleBarChart);