import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import withStyles from "@material-ui/styles/withStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { styles } from './common/CommonStyles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getBrackets, computeYourTax, formatCurrency, formatPercent, getBracketsState, computeYourStateTax } from './common/Utils';
import TopArrows from './common/TopArrows';
import TaxYearPopper from './common/TaxYearPopper';
import IncomePopper from './common/IncomePopper';
import FilingStatusPopper from './common/FilingStatusPopper';
import BottomNavi from './common/BottomNavi';
import { Box, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import SingleYearTabContent from './fragments/SingleYearTabContent';
import StatePopper from './common/StatePopper';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  noPadding: {
    padding: '0px'
  },
});

const SingleYearTable =(props) => {

  const recomputeState = (selectedState, selectedYear, selectedIncome, selectedFilingStatus ) => {
    let brackets3 = getBracketsState(selectedYear ?? taxYear, selectedFilingStatus ?? filingStatus, selectedState);
    if (brackets3 && brackets3.length > 0) {
      if (brackets3.length === 1 && brackets3[0].error) {
        setStateDateAvailable(brackets3[0].error);
      } else {
        let computedBrackets3 = computeYourStateTax(brackets3, selectedIncome ?? newTaxableIncome);
        //console.log("SingleYearTable.useEffect.ComputedBrackets", computedBrackets3, taxYear, filingStatus);
        setComputedBracketsState(computedBrackets3);
        setStateDateAvailable(null);  
      }
    } else {
      setStateDateAvailable("No Data Available");
    }

  } 
  const stateCallback = (selectedState) => {
    // console.log("yearCallback", selectedYear);
    setNewStateCode(selectedState);
    recomputeState(selectedState);
  };

  const yearCallback = (selectedYear) => {
    // console.log("yearCallback", selectedYear);
    setNewTaxYear(selectedYear);
    let brackets = getBrackets(selectedYear, filingStatus);
    if (brackets && brackets.length > 0) {
      let computedBrackets = computeYourTax(brackets, newTaxableIncome);
      // console.log("yearCallback", computedBrackets);
      setComputedBrackets(computedBrackets);  
      recomputeState(newStateCode, selectedYear);
    }
  };

  const filingStatusCallback = (selectedFilingStatus) => {
    // console.log("filingStatusCallback", selectedFilingStatus);
    setNewFilingStatus(selectedFilingStatus);
    let brackets = getBrackets(newTaxYear, selectedFilingStatus);
    if (brackets && brackets.length > 0) {
      let computedBrackets = computeYourTax(brackets, newTaxableIncome);
      // console.log("filingStatusCallback", computedBrackets);
      setComputedBrackets(computedBrackets);  
      recomputeState(newStateCode, null, null, selectedFilingStatus);
    }
  };

  const incomeCallback = (updatedIncome) => {
    // console.log("incomeCallback", updatedIncome);
    if (!updatedIncome) return;
    // console.log("incomeCallback", updatedIncome, newTaxYear, filingStatus);
    updatedIncome = updatedIncome.replace(/[^0-9.-]+/g,"");
    setNewTaxableIncome(updatedIncome);
    let brackets = getBrackets(newTaxYear, filingStatus);
    if (brackets && brackets.length > 0) {
      let computedBrackets = computeYourTax(brackets, updatedIncome);
      setComputedBrackets(computedBrackets);
      recomputeState(newStateCode, null, updatedIncome);
    }
  };

  // const classes = useStyles();
  // console.log("props", props);

  const { classes, location } = props;
  const customClasses = useStyles();
  let { filingStatus="", name='Dude', taxYear, taxableIncome='0', stateCode } = location.state 
    ? location.state 
    : {filingStatus: 'SIN', name: '', taxYear: '2011', taxableIncome: '0'};
  // console.log("locaiton state is ", location.state);
  const [newTaxYear, setNewTaxYear] = useState(taxYear);
  const [newStateCode, setNewStateCode] = useState(stateCode);
  taxableIncome = taxableIncome.replace(/[^0-9.-]+/g,"");
  const [newTaxableIncome, setNewTaxableIncome] = useState(taxableIncome);
  const [newFilingStatus, setNewFilingStatus] = useState(filingStatus);
  const [stateDateAvailable, setStateDateAvailable] = useState(null);
  
  

  const [computedBrackets, setComputedBrackets] = useState([]);
  const [computedBracketsState, setComputedBracketsState] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

  };
  useEffect(()=> {
    let brackets2 = getBrackets(taxYear, filingStatus);
    // console.log("SingleYearTable.useEffect", brackets2, taxYear, filingStatus);
    if (brackets2 && brackets2.length > 0) {
      let computedBrackets2 = computeYourTax(brackets2, taxableIncome);
      // console.log("SingleYearTable.useEffect.ComputedBrackets", computedBrackets2, taxYear, filingStatus);
      setComputedBrackets(computedBrackets2);
    }
    let brackets3 = getBracketsState(taxYear, filingStatus, stateCode);
    //console.log("SingleYearTable.useEffect", brackets3, taxYear, filingStatus, stateCode);
    if (brackets3 && brackets3.length > 0) {
      let computedBrackets3 = computeYourStateTax(brackets3, taxableIncome);
      //console.log("SingleYearTable.useEffect.ComputedBrackets", computedBrackets3, taxYear, filingStatus);
      setComputedBracketsState(computedBrackets3);
    }

  }, [])

  
  let totalTaxes = computedBrackets && computedBrackets.length > 0 ? computedBrackets.reduce( (sofar, item) => {
    return {incomeTax: Number(sofar.incomeTax ? sofar.incomeTax : 0) + Number(item.incomeTax ? item.incomeTax : 0) };
  }) : { incomeTax: 0 };
  let totalTaxesState = computedBracketsState && computedBracketsState.length > 0 ? computedBracketsState.reduce( (sofar, item) => {
    return {incomeTax: Number(sofar.incomeTax ? sofar.incomeTax : 0) + Number(item.incomeTax ? item.incomeTax : 0) };
  }) : { incomeTax: 0 };
  let naviState = {
    filingStatus: newFilingStatus,
    taxYear: newTaxYear,
    taxableIncome: newTaxableIncome
  };
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <Back /> */}
      <TopArrows back={true} next={true} nextText="Timeline View" nextPath="/timeline" nextState={naviState} backState={naviState}/>
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid
              spacing={4}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}
            >
            <Grid item xs={12} >
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        <Typography variant="body1" gutterBottom>
                          Here is your approximate Federal Income taxes you owe based on the information you provided. <br />
                          You may change the filing status, the tax year, or your taxable income directly from here see your
                          break down for another year.  Or you can click on the to right navigation link to see a timeline view 
                          since 2011. <br /><br />
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                          Year: <TaxYearPopper currentYear={taxYear} yearCallback={yearCallback} />, Income: 
                            <IncomePopper currentIncome={formatCurrency(taxableIncome)} incomeCallback={incomeCallback} />, 
                            Status: <FilingStatusPopper currentFilingStatus={filingStatus} filingStatusCallback={filingStatusCallback} />,  
                            State: <StatePopper currentState={stateCode} stateCallback={stateCallback} /> 
                        </Typography>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                </TableContainer>
                  <TabContext value={tabValue}>
                    {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                      <Tabs value={tabValue} component={Paper} onChange={handleTabChange} aria-label="Federal and State Tax Tab" variant="fullWidth">
                        <Tab label="Federal" id='simple-tab-0' />
                        <Tab label="State"  id='simple-tab-1' />
                      </Tabs>
                    {/* </Box> */}
                    <TabPanel value={0} index={0} className={customClasses.noPadding}>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="spanning table">
                          <SingleYearTabContent 
                            computedBrackets={computedBrackets} 
                            totalTaxes={totalTaxes} 
                            newTaxableIncome={newTaxableIncome}
                            naviState={naviState}
                            dataAvailable={null} />
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={1} index={1} className={customClasses.noPadding}>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="spanning table">
                          <SingleYearTabContent 
                            computedBrackets={computedBracketsState} 
                            totalTaxes={totalTaxesState} 
                            newTaxableIncome={newTaxableIncome}
                            naviState={naviState}
                            dataAvailable={stateDateAvailable} />
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabContext>
            </Grid>
          </Grid>          
        </Grid>
      </div>
    </React.Fragment>
  );
}
export default withRouter(withStyles(styles)(SingleYearTable));