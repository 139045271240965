const Menu = [
  {
    label: "Home",
    pathname: "/"
  },
  {
    label: "About",
    pathname: "/aboutus"
  },
  {
    label: "FAQs",
    pathname: "/faqs"
  }
  // {
  //   label: "Wizard",
  //   pathname: "/wizard"
  // },
  // {
  //   label: "Cards",
  //   pathname: "/cards"
  // },
  // {
  //   label: "Github",
  //   pathname: "https://github.com/alexanmtz/material-sense",
  //   external: true
  // }

];

export default Menu;