import { React } from 'react';
import AdSense from 'react-adsense';

// MyTaxes-Display1
const AdSenseWdiget = () => {
  return (
    <AdSense.Google
          client='ca-pub-4682642215710738'
          slot='2628629541'
          style={{ display: 'block' }}
          format='auto'
          responsive='true'
        />
  )
};

export default AdSenseWdiget;