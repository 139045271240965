import Constants from './Constants';

const FederalTaxBrackets = require('../../data/federal/FederalTaxBrackets.json');
const {default: stateBrackets} = require('../../data/state');


const democratYears = ["2011", "2012", "2013", "2014", "2015", "2016", "2021", "2022", "2023", "2024"];
const republicanYears = ["2017", "2018", "2019", "2020"];

const getYearsArray = () => {
  const years = [];
  let entries = Object.entries(FederalTaxBrackets);
  entries.forEach(item => {
    years.push(item[1].year);
  })
  return years;
};

const getYearsCombo = () => {
  const years = [];
  let entries = Object.entries(FederalTaxBrackets);
  entries.forEach(item => {
    years.push({ value: item[1].year, label: item[1].year});
  })
  return years;
};

const getBrackets = (year, filingStatus) => {
  if (!FederalTaxBrackets[year]) {
    console.log("returning []");
    return [];
  }
  if (!filingStatus) {
    return FederalTaxBrackets[year].filingStatuses
  } else {
    let bracketsForFS = FederalTaxBrackets[year].filingStatuses.filter(item=>item.filingStatus === filingStatus);
    return bracketsForFS && bracketsForFS.length > 0 && bracketsForFS[0].brackets 
    ? bracketsForFS[0].brackets
    : []
  }
};

const getBracketsState = (year, filingStatus, stateCode) => {
  // console.log("getBracketsState.stateBrackets=", stateBrackets, year);
  if (!stateBrackets) {
    console.log("returning []");
    return [];
  }
  const yearStates = stateBrackets.find((i) => Object.keys(i)[0] === year);
  if (!yearStates) return [];
  // console.log("Finding year state", yearStates);
  let stateStatuses = {};
  if (!stateCode) {
    return [];
  } else {
    stateStatuses = yearStates[year].find((i) => i.state === stateCode);
  }
  if (!filingStatus) {
    return stateStatuses.filingStatuses
  } else {
    //If no filingStatuses attribute, then it means the state has no income tax look for incomeTax attribute.
    if (stateStatuses.filingStatuses) {
      let bracketsForFS = stateStatuses.filingStatuses.filter(item=>item.filingStatus === filingStatus);
      return bracketsForFS && bracketsForFS.length > 0 && bracketsForFS[0].brackets 
      ? bracketsForFS[0].brackets
      : []  
    } else {
      return [{error: "State has no income tax"}];
    }
  }
};

const computeYourTax = (brackets, incomeStr) => {
  if (!incomeStr || Number(incomeStr) <= 0) return brackets;

  // if (!income) console.log("income is nothing");
  // console.log("Number is", Number(incomeStr))


  let bracketsCopy = JSON.parse(JSON.stringify(brackets));
  let i = 0;
  let income = Number(incomeStr)
  //find the max tax bracket.
  for (i=0; i < brackets.length; i++) {
    if (income >= Number(brackets[i].incomeLow) && (income <= Number(brackets[i].incomeHigh) || brackets[i].incomeHigh==="")) {
      break;
    }
  }
  // console.log("computer i is", i);
  //apply the taxes.
  let accumulated = 0;
  for (let j =0; j <= i; j++) {
    let incomeTax = 0;
    if (j==i) {
      incomeTax = ( (brackets[j].rate/100) * (income - Number(brackets[j].incomeLow)));
    } else {
      incomeTax = ( (brackets[j].rate/100) * (Number(brackets[j].incomeHigh) - Number(brackets[j].incomeLow)));
    }
    accumulated = accumulated + incomeTax;
    bracketsCopy[j].incomeTax = incomeTax;  
    bracketsCopy[j].accumulated = accumulated;
  }

  return bracketsCopy;
};

const computeYourStateTax = (brackets, incomeStr) => {
  return computeYourTax( brackets, incomeStr);
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value ? value : '0');
}

const formatPercent = (value) => {
  var option = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
 };
 var formatter = new Intl.NumberFormat("en-US",option);
 return formatter.format(value);
}


const getBarFill = (year, theme) => {
  if (republicanYears.includes(year)) return theme.palette.error.light;
  if (democratYears.includes(year)) return  theme.palette.primary.light;
  return theme.palette.grey[500];
}


const getStatesCombo = () => {
  return [
    { value: "AL", label: "Alabama"},
    { value: "AK", label: "Alaska"},
    { value: "AZ", label: "Arizona"},
    { value: "AR", label: "Arkansas"},
    { value: "CA", label: "California"},
    { value: "CO", label: "Colorado"},
    { value: "CT", label: "Conneticut"},
    { value: "DE", label: "Delaware"},
    { value: "FL", label: "Florida"},
    { value: "GA", label: "Georgia"},
    { value: "HI", label: "Hawaii"},
    { value: "ID", label: "Idaho"},
    { value: "IL", label: "Illinois"},
    { value: "IN", label: "Indiana"},
    { value: "IA", label: "Iowa"},
    { value: "KS", label: "Kansas"},
    { value: "KY", label: "Kentucky"},
    { value: "LA", label: "Louisiana"},
    { value: "ME", label: "Maine"},
    { value: "MD", label: "Maryland"},
    { value: "MA", label: "Massachussetts"},
    { value: "MI", label: "Michigan"},
    { value: "MN", label: "Minnesota"},
    { value: "MS", label: "Mississipi"},
    { value: "MO", label: "Missouri"},
    { value: "MT", label: "Montana"},
    { value: "NE", label: "Nebraska"},
    { value: "NV", label: "Nevada"},
    { value: "NH", label: "New Hampshire"},
    { value: "NJ", label: "New Jersey"},
    { value: "NM", label: "New Mexico"},
    { value: "NY", label: "New York"},
    { value: "NC", label: "North Carolina"},
    { value: "ND", label: "North Dakota"},
    { value: "OH", label: "Ohio"},
    { value: "OK", label: "Oklahoma"},
    { value: "OR", label: "Oregon"},
    { value: "PA", label: "Pennslyvania"},
    { value: "RI", label: "Rhode Island"},
    { value: "SC", label: "South Carolina"},
    { value: "SD", label: "South Dakota"},
    { value: "TN", label: "Tennessee"},
    { value: "TX", label: "Texas"},
    { value: "UT", label: "Utah"},
    { value: "VT", label: "Vermont"},
    { value: "VA", label: "Virginia"},
    { value: "WA", label: "Washington"},
    { value: "WV", label: "West Virginia"},
    { value: "WI", label: "Wisconsin"},
    { value: "WY", label: "Wyoming"},
    { value: "DC", label: "District of Columbia"}        
  ]
};

const getStatesArray = () => {
  const states = [];
  getStatesCombo().forEach(item => {
    states.push(item.value);
  })
  return states;
};


export { 
  getYearsArray, 
  getYearsCombo, 
  getBrackets, 
  computeYourTax, 
  formatCurrency, 
  formatPercent, 
  getBarFill, 
  getStatesCombo,
  getStatesArray,
  getBracketsState, 
  computeYourStateTax };