import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SimpleBarChart from "./SimpleBarChart";
import Loading from "./common/Loading";

import TopArrows from "./common/TopArrows";
import { computeYourTax, getBrackets, getYearsCombo } from "./common/Utils";
import AdSenseWdiget from "./common/AdSenseWidget";
import Box from "@material-ui/core/Box";
import UsefulLinks from "./fragments/UsefulLinks";


const backgroundShape = require("../images/shape.svg");

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["100"],
    overflow: "hidden",
    background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    paddingBottom: 200
  },
  grid: {
    width: 1200,
    margin: `0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  loadingState: {
    opacity: 0.05
  },
  paper: {
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1),
    width: 152,
    height: 36
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  block: {
    padding: theme.spacing(2)
  },
  loanAvatar: {
    display: "inline-block",
    verticalAlign: "center",
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  interestAvatar: {
    display: "inline-block",
    verticalAlign: "center",
    width: 16,
    height: 16,
    marginRight: 10,
    marginBottom: -2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  noBorder: {
    borderBottomStyle: "hidden"
  },
  mainBadge: {
    textAlign: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  }
});

// const yearRange = Years;

class TaxRangeResults extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      amount: 15000,
      period: 3,
      start: 0,
      monthlyInterest: 0,
      totalInterest: 0,
      monthlyPayment: 0,
      totalPayment: 0,
      data: []
    };
  }

  updateValues() {

    const { location } = this.props;
    let { filingStatus="", name='Dude', taxYear='', taxableIncome='0' } = location.state 
    ? location.state 
    : {filingStatus: 'SIN', name: '', taxYear: '2011', taxableIncome: '0'};
    // this.setState({ filingStatus, taxYear, taxableIncome});
    // console.log("locaiton state is ", location.state);

    // if (!filingStatus || !taxYear || !taxableIncome) return;
    //get the years
    let allYears = getYearsCombo().filter(item => Number(item.value) > 2011);


    //get all the brackets. 
    let allBrackets = [];
    allYears.forEach(item => {
      let bracketYear = getBrackets(item.value, filingStatus);
      bracketYear = computeYourTax(bracketYear, taxableIncome);
      let year = item.value;
      let obj = {};
      obj[year] = bracketYear;
      allBrackets.push(obj)
    })
    // console.log("AllBrackets is ", allBrackets);
    

    //put togethre the data object for display;
    let data2 = [];
    allBrackets.forEach(item => {
      // console.log("processing item", item);
      // console.log("entries is ", Object.entries(item)[0]);
      let entries = Object.entries(item)[0];
      let totalTax = 0;
      entries[1].forEach(bracket => {
        totalTax = totalTax + ( bracket.incomeTax ? Number(bracket.incomeTax) : 0);
      })
      let obj = {
        "TaxYear" : entries[0],
        "IncomeTax" : totalTax
      }
      data2.push(obj);
    });

    // console.log("data2 obj", data2);
    this.setState({loading: false, data2: data2});
  }

  componentDidMount() {
    // console.log("componentDidMount",this.props);
    this.updateValues();
  }

  render() {
    const { classes, location } = this.props;
    let { filingStatus="", name='Dude', taxYear='', taxableIncome='0' } = location.state 
    ? location.state 
    : {filingStatus: 'SIN', name: '', taxYear: '2011', taxableIncome: '0'};

    // this.setState({ filingStatus, taxYear, taxableIncome});
    // console.log("locaiton state is 1", location.state);
    const {
      data2,
      loading
    } = this.state;
    
    return (
      <React.Fragment>
        <CssBaseline />
        <TopArrows back={true} backPath="/taxresults" backText='Back' backState={ {filingStatus, taxYear, taxableIncome} } />
        <div className={classes.root}>
          <Grid container justify="center">
            <Grid
              spacing={10}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}
            >
              <Grid container spacing={4} justify="center">
                <Grid item xs={12} md={8}>
                  <Paper
                    className={classes.paper}
                    style={{ position: "relative" }}
                  >
                    <Loading loading={loading} />
                    <div className={loading ? classes.loadingState : ""}>
                      <Typography variant="subtitle1" gutterBottom>
                        Based on the information you provided on the previous page, the bar graph depicts how much federal income tax 
                        you would've paid in a given year based on your provided taxable income and filing status.
                        <br />
                        It is color coded based on the party affiliation so you can get an idea of who really has your best financial 
                        interests in mind.  
                        <br />
                        <strong>NOTE:</strong>  This does not take into account the various deductions you may be eligible for to lower your taxable income.
                      </Typography>
                      <Box pt={2}>
                        <SimpleBarChart data={data2} />
                      </Box>
                      <Typography variant="subtitle1" gutterBottom>
                        In some states like California, state and local taxes (known as SALT) can quickly add up.  That will reduce your taxale income, but not by much.  Visit our <a target="_blank" href="/faqs">FAQ</a> page for more info on this.  
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper
                    className={classes.paper}
                    style={{ position: "relative" }}
                  >
                    <Loading loading={loading} />
                    <UsefulLinks />
                    <div className={loading ? classes.loadingState : ""}>
                      <AdSenseWdiget />
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(TaxRangeResults));
