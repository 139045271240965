import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Topbar from "./Topbar";
import { getYearsCombo } from "./common/Utils";
import Box from "@material-ui/core/Box";

const backgroundShape = require("../images/shape.svg");

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey["100"],
    overflow: "hidden",
    background: `url(${backgroundShape}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
    paddingBottom: 200
  },
  grid: {
    width: 1200,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 20px)"
    }
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: "left",
    color: theme.palette.text.secondary
  },
  rangeLabel: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2)
  },
  topBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 32
  },
  outlinedButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1)
  },
  actionButtom: {
    textTransform: "uppercase",
    margin: theme.spacing(1),
    width: 152
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  block: {
    padding: theme.spacing(2)
  },
  box: {
    marginBottom: 40,
    height: 65
  },
  inlining: {
    display: "inline-block",
    marginRight: 10
  },
  buttonBar: {
    display: "flex"
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end"
  },
  noBorder: {
    borderBottomStyle: "hidden"
  },
  loadingState: {
    opacity: 0.05
  },
  loadingMessage: {
    position: "absolute",
    top: "40%",
    left: "40%"
  },
  formSpacing: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    width: '55ch'
  }
});

const taxyears = getYearsCombo();

class AboutUs extends Component {
  

  constructor(props) {
    super(props)

    this.state = {
    };
}
  componentDidMount() {
  }



  render() {
    const { classes } = this.props;
    // console.log("Render props", this.props.history);
    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar />
        <div className={classes.root}>
          <Grid container justify="center">
            <Grid
              spacing={4}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}
            >
              <Grid container item xs={12}>
                <Grid item>
                  <Paper className={classes.paper}>
                    <div>
                      <Box pb={5}>
                      <div>
                        <Typography variant="h3" >
                          About this website
                        </Typography>
                        <Typography variant="body1" >
                          This website is dedicated to help the average tax payer understand how much you are actually paying in taxes.  
                          So far we are posting Federal income taxes and we are looking to expand to State income taxes as well. 
                        </Typography>
                        <Typography variant="body1" >
                          <br />
                          The process is simple.  On the home page, let us know what your Filing Status is (or was), your taxable income, and your tax year.
                          The taxable income is often found from the Federal IRS form 1040 line 15 (for year 2020).  
                          If you don't have it handy, you can always estimate based on the previous year's taxable income. 
                        </Typography>
                        <Typography variant="body1" >
                          <br />
                          You can find out what your maximum tax bracket is for any given year, your effective tax rate, and how much tax you actually
                          paid in each tax bracket.   You can also view a timeline of how much taxes you would've paid over time.  So you can understand
                          whether your taxes are going up or coming down.  
                        </Typography>
                      </div>
                      </Box>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(AboutUs));
